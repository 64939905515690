var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menus" },
    [
      _c(
        "el-container",
        [
          _c("el-header", [
            _c("div", { staticClass: "titlename" }, [
              _c("img", {
                attrs: { src: require("@/assets/image/logao.png"), alt: "" },
              }),
              _vm._v("农产品集采平台后台管理系统 "),
            ]),
            _c(
              "div",
              { staticClass: "outtab" },
              [
                _c(
                  "el-popover",
                  {
                    attrs: { placement: "top", width: "160" },
                    model: {
                      value: _vm.visible,
                      callback: function ($$v) {
                        _vm.visible = $$v
                      },
                      expression: "visible",
                    },
                  },
                  [
                    _c("div", { staticClass: "tiimgs pointer" }, [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/image/my.png"),
                          alt: "",
                        },
                      }),
                      _c("div", [_vm._v(_vm._s(_vm.info.nickname))]),
                      _c(
                        "div",
                        {
                          staticClass: "btn pointer",
                          on: { click: _vm.latout },
                        },
                        [_vm._v("退出登录")]
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "imgti pointer",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/image/my.png"),
                            alt: "",
                          },
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.info.nickname))]),
                        _c("i", { staticClass: "el-icon-arrow-down" }),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-container",
            [
              _c(
                "el-aside",
                [
                  _c(
                    "el-menu",
                    {
                      staticClass: "el-menu-vertical-demo",
                      attrs: {
                        "default-active": _vm.$route.path,
                        router: "",
                        "unique-opened": true,
                      },
                    },
                    _vm._l(_vm.menuslist, function (item) {
                      return _c(
                        "div",
                        { key: item.path },
                        [
                          item.children.length > 0
                            ? _c(
                                "el-submenu",
                                { attrs: { index: item.path } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c("img", {
                                      staticClass: "imgs",
                                      attrs: { src: item.url, alt: "" },
                                    }),
                                    _c("span", [_vm._v(_vm._s(item.name))]),
                                  ]),
                                  _vm._l(item.children, function (list) {
                                    return _c(
                                      "el-menu-item",
                                      {
                                        key: list.path,
                                        attrs: { index: list.path },
                                      },
                                      [_vm._v(_vm._s(list.name))]
                                    )
                                  }),
                                ],
                                2
                              )
                            : _c(
                                "div",
                                [
                                  _c(
                                    "el-menu-item",
                                    { attrs: { index: item.path } },
                                    [
                                      _c("img", {
                                        staticClass: "imgs",
                                        attrs: { src: item.url, alt: "" },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "title" },
                                          slot: "title",
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c("el-main", [
                _c(
                  "div",
                  { staticClass: "sbts" },
                  [
                    _c(
                      "transition",
                      { attrs: { name: "fade", mode: "out-in" } },
                      [_c("router-view")],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }